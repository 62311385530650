<template>
  <el-tabs v-model="activeName" class="management" @tab-click="handleClick">
    <!-- <el-tab-pane label="LoGo" name="first">
      <el-form ref="form">
        <el-row>
          <el-col style="margin-top:10px">
            <el-form-item label="LoGo:">
              <el-upload
                class="avatar-uploader"
                action="/api/hnhz/file/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" class="bottn" :loading="subLoading" @click="handerSubmitlogo">提交</el-button>
      <el-button type="primary" class="bottns" @click="Handresetlogo">取消</el-button>
    </el-tab-pane> -->

    <el-tab-pane label="公司地址" name="second">
      <el-form ref="form2" label-width="100px" style="margin-top:30px">
        <el-form-item label="经度">
          <el-input v-model="form2.longitude" autocomplete="off" />
        </el-form-item>

        <el-form-item label="纬度">
          <el-input v-model="form2.latitude" autocomplete="off" />
        </el-form-item>

        <el-form-item label="地址">
          <el-input v-model="form2.companyAddress" autocomplete="off" type="textarea" style="width:400px" />
        </el-form-item>
      </el-form>
      <el-button type="primary" class="bottn" :loading="subLoading" @click="handerSubmitaddress">保存</el-button>
      <!-- <el-button type="primary" class="bottns" @click="Handresetaddress">取消</el-button> -->
    </el-tab-pane>

    <el-tab-pane label="客服信息" name="third">
      <el-form ref="form3" label-width="100px" style="margin-top:30px">
        <el-row>
          <el-col>
            <el-form-item label="微信">
              <!-- <el-input v-model="form3.vchat" autocomplete="off" >
              </el-input> -->
              <el-upload
                class="avatar-uploader"
                action="/api/hnhz/file/upload"
                :show-file-list="false"
                :on-success="handleWechatImgSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form3.vchat" :src="form3.vchat" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>

            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="QQ">
              <el-input v-model="form3.qq" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="邮箱">
              <el-input v-model="form3.email" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="电话">
              <el-input v-model="form3.phone" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" class="bottn" @click="handerSubmitcustomer">保存</el-button>
      <!-- <el-button type="primary" class="bottns" @click="Handresetcustomer">取消</el-button> -->
    </el-tab-pane>
    <el-tab-pane label="备案信息" name="fourth">
      <el-form ref="form4" label-width="100px" style="margin-top:30px">
        <el-row>
          <el-col>
            <el-form-item label="时间">
              <el-input v-model="form4.forTheRecordTime" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="备案号">
              <el-input v-model="form4.internetContentProvider" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="备案地址">
              <el-input v-model="form4.forTheRecordAddress" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" class="bottn" @click="handerSubmitkeep">保存</el-button>
      <!-- <el-button type="primary" class="bottns" @click="Handresetkeep">取消</el-button> -->
    </el-tab-pane>
    <el-tab-pane label="首页" name="homepage">
      <el-form ref="form5" label-width="100px" style="margin-top:30px">
        <el-row>
          <el-col style="margin-top:10px">
            <el-form-item label="banner:">
              <!-- <el-upload
                class="avatar-uploader"
                action="/api/hnhz/file/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form5.imgUrl" :src="form5.imgUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>-->
              <el-upload
                action="/api/hnhz/file/upload"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="uploadAttachments"
                :on-success="handleUploadBannerSuccess"
              >
                <i class="el-icon-plus" />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt>
              </el-dialog>
            </el-form-item>
          </el-col>
          <!-- <el-col>
            <el-form-item label="主标题">
              <el-input v-model="form5.title" />
            </el-form-item>
          </el-col>-->
        </el-row>
      </el-form>
      <el-button type="primary" class="bottn" @click="handerSubmithome">保存</el-button>
      <!-- <el-button type="primary" class="bottns" @click="Handresethome">取消</el-button> -->
    </el-tab-pane>
    <!-- <el-tab-pane label="关于页" name="aboutpage">
      <el-form ref="form6">
        <el-row>
          <el-col style="margin-top:10px">
            <el-form-item label="banner:">
              <el-upload
                class="avatar-uploader"
                action="/api/hnhz/file/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form6.imgUrl" :src="form6.imgUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col>
            <el-divider content-position="left">公司简介</el-divider>
            <el-form-item label="标题">
              <el-input />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="简介">
              <el-input />
            </el-form-item>
          </el-col>
          <el-col>
            <el-divider content-position="left">团队简介</el-divider>
            <el-form-item label="标题">
              <el-input />
            </el-form-item>
            <el-form-item label="简介">
              <el-input />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" class="bottn" @click="handerSubmit">提交</el-button>
      <el-button type="primary" class="bottns" @click="Handreset">取消</el-button>
    </el-tab-pane>-->
  </el-tabs>
</template>
<script>
import { getSubmit, getSubmitads, getSubmitatt, getSubmitakeep, obtaindss, obtaintt, obtainkeep, getbanner, savebanner } from '@/api/admin/about'
import { } from '../../api/admin/homePage'
export default {
  data() {
    return {
      activeName: 'second',
      form: {
        imgUrl: ''
      },
      form2: {
        longitude: '',
        latitude: '',
        companyAddress: ''
      },
      form3: {
        email: '',
        phone: '',
        qq: '',
        vchat: ''
      },
      form4: {
        forTheRecordTime: '',
        forTheRecordAddress: '',
        internetContentProvider: ''
      },
      subLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      uploadAttachments: []
    }
  },
  created() {
    this.getobtaindss()
    this.getobtaintt()
    this.getobtainkeep()
    this.gethandlebanner()
  },
  methods: {

    handleClick(tab, event) {
      console.log(tab, event)
    },
    // logo提交
    handerSubmitlogo() {
      this.subLoading = true
      this.form.id = ''
      getSubmit(this.form)
        .then(res => {
          this.subLoading = false
          this.form.imgUrl = ''
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(e => {
          this.subLoading = false
          this.$message({
            message: '提交失败',
            type: 'danger'
          })
        })
    },
    // logo点击取消
    Handresetlogo() {
      this.form.imgUrl = ''
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log('handleAvatarSuccess', res, file)
      this.form.imgUrl = res.data.filePath
      this.form6.imgUrl = res.data.filePath
    },
    handleWechatImgSuccess(res) {
      this.form3.vchat = res.data.filePath
    },
    // 移除头像
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.uploadAttachments = fileList
    },
    // 预览banner
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return isJPG && isLt2M
      return isJPG
    },
    // 提交公司地址
    handerSubmitaddress() {
      this.subLoading = true
      this.form2.id = ''
      getSubmitads(this.form2)
        .then(res => {
          this.subLoading = false
          // this.form2 = {}
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(e => {
          this.subLoading = false
          this.$message({
            message: '提交失败',
            type: 'danger'
          })
        })
    },
    // 取消提交地址
    Handresetaddress() {
      this.form2 = {}
    },
    // 提交客服信息
    handerSubmitcustomer() {
      this.subLoading = true
      this.form3.id = ''
      getSubmitatt(this.form3)
        .then(res => {
          this.subLoading = false
          // this.form3 = {}
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(e => {
          this.subLoading = false
          this.$message({
            message: '提交失败',
            type: 'danger'
          })
        })
    },
    // 取消提交客服信息
    Handresetcustomer() {
      this.form3 = {}
    },
    // 备案信息提交
    handerSubmitkeep() {
      this.subLoading = true
      this.form4.id = ''
      getSubmitakeep(this.form4)
        .then(res => {
          this.subLoading = false
          // this.form4 = {}
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(e => {
          this.subLoading = false
          this.$message({
            message: '提交失败',
            type: 'danger'
          })
        })
    },
    // 取消提交备案信息
    Handresetkeep() {
      this.form4 = {}
    },
    // 首页提交
    handerSubmithome() {
      this.subLoading = true
      savebanner(this.uploadAttachments.map(o => o.url))
        .then(res => {
          this.subLoading = false
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(e => {
          this.subLoading = false
          this.$message({
            message: '提交失败',
            type: 'danger'
          })
        })
    },
    // 获取首页banner图
    gethandlebanner() {
      getbanner()
        .then(res => {
          console.log('getbanner', res)
          this.uploadAttachments = res.data.item.map(o => {
            return {
              name: o.detail,
              url: o.imgUrl
            }
          })
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 公司地址获取
    getobtaindss() {
      obtaindss()
        .then(res => {
          console.log('res', res)
          this.form2 = res.data.companyAddress
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 获取客服信息
    getobtaintt() {
      obtaintt()
        .then(res => {
          console.log('res', res)
          this.form3 = res.data.contactInformation
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 备案信息
    getobtainkeep() {
      obtainkeep()
        .then(res => {
          console.log('res', res)
          this.form4 = res.data.archivalInformation
        })
        .catch(e => {
          console.error(e)
        })
    },
    handleUploadBannerSuccess(res, file, fileList) {
      file.url = res.data.filePath
      console.log('handleUploadBannerSuccess', fileList)
      this.uploadAttachments = fileList
      console.log('handleUploadBanner2', this.uploadAttachments)
    }
  }
}
</script>
<style lang="scss">
.management {
  .el-input__inner {
    width: 400px;
  }
  /** 菜单栏和内容 */
  .el-tabs__nav {
    margin-left: 40px;
  }
  .el-tabs__content {
    margin-left: 30px;
  }
  /** Logo上传和按钮 */
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .bottn {
    margin-left: 417px;
    width: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .bottns {
    width: 150px;
  }
}
</style>
